import axios from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const getDispatchRoutes = async () => {
    try {
        const dispatchRoutes = await axios.get(`${API_URL}dispatchRoutes/`);
        return dispatchRoutes.data;
    }
    catch (error) {
        alert("Error al obtener rutas de despacho")
        throw Error("Error en el servidor al obtener rutas de despacho")
    }
}

export const insertDispatchRoutes = async (dispatchRoutesList: DispatchRoute[]) => {
    try {
        await axios.post(`${API_URL}dispatchRoutes/`, {
            dispatchRoutesList
        });
    }
    catch {
        throw Error("Hubo un error en el servidor al cargar las rutas de despacho")
    }
}