import Excel from "exceljs";

export async function readDispatchRoutes(file) {
    const reader = new FileReader();
    const wb = new Excel.Workbook();

    reader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
        reader.onload = async () => {
            try {
                const buffer = reader.result;
                await wb.xlsx.load(buffer);
                
                const rowsToArray = [];
                const worksheet = wb.worksheets[0];

                if (worksheet._rows.length < 2) {
                    reject(`La hoja de excel está vacía`);
                }
                worksheet.eachRow((row, rowNumber) => {
                    if (rowNumber === 1) {
                        if (!row.getCell(1).value || row.getCell(1).value !== "Comuna") {
                            reject(`El formato del excel no coincide con el esperado`);
                        }
                        if (!row.getCell(2).value || row.getCell(2).value !== "Rutas") {
                            reject(`El formato del excel no coincide con el esperado`);
                        }
                    } else if (rowNumber > 1) { 
                        if (!row.getCell(1).value || !row.getCell(2).value) {
                            reject(`Fila número ${rowNumber} tiene un valor indefinido o no se respeta el formato esperado`);
                        }
                        const rowToArray = {
                            commune: row.getCell(1).value || "",
                            routeName: row.getCell(2).value.toString() || "",
                        };
                        rowsToArray.push(rowToArray);
                    }
                });

                resolve(rowsToArray);
            } catch (error) {
                reject(`${error.message}`);
            }
        };

        reader.onerror = () => {
            reject("Error al leer el archivo con FileReader.");
        };
    });
}
