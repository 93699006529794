import {
    DataGridPro,
    GridColDef,
    esES,
    GridToolbar,
} from "@mui/x-data-grid-pro";
import { Button, Card, Alert } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from "../../Logo";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { getDispatchRoutes, insertDispatchRoutes } from "../../../services/dispatchRoutesService";
import { readDispatchRoutes } from "../../../utils/readRoutesExcel";


const DispatchRoutesView = () => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ routes, setRoutes ] = useState<DispatchRoute[]>([]);


    const handleLoadRoutes = async (e: any) => {
        const file = e.target.files[0];

        try {
            setIsLoading(true);
            const routeList = await readDispatchRoutes(file);
            await insertDispatchRoutes(routeList);
            await fetchRoutes();
            alert("Rutas cargadas exitosamente.");
        } catch (err) {
            alert(`Error: ${err}`)
        } finally {
            e.target.value = "";
            setIsLoading(false);
        }
    }

    const fetchRoutes = async () => {
        try {
            setIsLoading(true);
            const routes = await getDispatchRoutes();
            setRoutes(routes);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchRoutes();
    }, []);

    const columns: GridColDef[] = [
        { field: "commune", minWidth: 150, flex: 1, headerName: "Comuna" },
        { field: "routeName", minWidth: 150, flex: 1, headerName: "Ruta" },
    ];

    return (
        <div className="p-5 h-full overflow-auto">
        <Logo />
        <div className="text-xl mb-5">Rutas de despacho</div>
        <div>
            <input
                accept=".xls, .xlsx"
                style={{ display: "none" }}
                id="charge-file-button"
                type="file"
                onChange={(e) => handleLoadRoutes(e)}
                disabled={isLoading}
            />
            <label htmlFor="charge-file-button">
                <Button variant="contained" component="span" disabled={isLoading}>
                    Cargar rutas de despacho
                </Button>
            </label>
        </div>

        <div>
            {isLoading ? (
                <div className="flex justify-center w-full col-span-full">
                    <Icon spin path={mdiCircleSlice1} className="w-7"></Icon>
                </div>
            ) : (
                <Card>
                    <DataGridPro
                        sx={{ padding: 2 }}
                        autoHeight={true}
                        columns={columns}
                        rows={routes}
                        pageSize={20}
                        getRowId={(row) => row._id}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        pagination
                    />
                </Card>
            )}
        </div>
    </div>
    );
  };
  export default DispatchRoutesView;